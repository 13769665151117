import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'
import i18n from '@/libs/i18n'

export default function usePostCategoriesList() {
	const refPostCategoriesListTable = ref(null)

	const tableColumns = [
		{ key: 'index', label: '#' },
		{ key: 'name', label: i18n.t('Name') },
		{ key: 'name_en', label: i18n.t('English Name') },
		{ key: 'status', label: i18n.t('Status') },
		{ key: 'created_by', label: i18n.t('Created by') },
		{ key: 'created_at', label: i18n.t('Created at') },
		{ key: 'updated_at', label: i18n.t('Updated at') },
		{ key: 'action', label: i18n.t('Actions') },
	]
	const perPage = ref(25)
	const totalPostCategories = ref(0)
	const currentPage = ref(1)

	const refetchData = () => {
		refPostCategoriesListTable.value.refresh()
	}

	watch([currentPage], () => {
		refetchData()
	})

	const fetchPostCategories = (ctx, callback) => {
		store
			.dispatch('frontend/fetchPostCategories', {
				page: currentPage.value,
			})
			.then(response => {
				if (response.data.code == '00') {
					callback(response.data.data)
					totalPostCategories.value = response.data.count
					perPage.value = currentPage.value == 1 ? response.data.data.length : 25
				}
			})
	}

	const dataMeta = computed(() => {
		const localItemsCount = refPostCategoriesListTable.value ? refPostCategoriesListTable.value.localItems.length : 0
		return {
			from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
			to: perPage.value * (currentPage.value - 1) + localItemsCount,
			of: totalPostCategories.value,
		}
	})

	const resolveStatus = status => {
		if (status === 1) return { label: i18n.t('Active'), variant: 'success' }
		if (status === 2) return { label: i18n.t('Inactive'), variant: 'danger' }
	}

	return {
		fetchPostCategories,
		tableColumns,
		perPage,
		currentPage,
		totalPostCategories,
		dataMeta,
		refPostCategoriesListTable,
		refetchData,
		resolveStatus,
	}
}
